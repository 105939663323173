import React from 'react';
import ReactDOM from 'react-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import { config } from './AzureAd/config'; 
import App from './App'; 
import './index.css';


const publicClientApplication = new PublicClientApplication({
  auth: {
    clientId: config.appId,
    redirectUri: config.redirectUrl,
    authority: config.authority,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true,
  },
});

async function performSilentRefresh(user) {
  try {
    const silentRequest = {
      account: user,
      scopes: config.scope,
    };
    const response = await publicClientApplication.acquireTokenSilent(silentRequest);
    console.log('Token refreshed:', response);
  } catch (error) {
    console.error('Token refresh error:', error);
  }
}

async function checkLoginStatusAndRender() {

  await publicClientApplication.initialize();
  const response = await publicClientApplication.handleRedirectPromise();
  console.log('response', response);
  const accounts = publicClientApplication.getAllAccounts();
  console.log('acc', accounts);

  if (accounts.length > 0) {
    const user = accounts[0];
    if (user.idTokenClaims) {
      const idTokenClaims = user.idTokenClaims;
     
    
      const now = Math.floor(Date.now() / 1000);
      const expirationTime = idTokenClaims.exp;
      const timeUntilExpiration = expirationTime - now;
      const refreshInterval = Math.max(0, timeUntilExpiration - 300);
    
      if (refreshInterval > 0) {
        setTimeout(() => {
          performSilentRefresh(user);
        }, refreshInterval * 1000);
      } else {
        console.log('Token already expired or about to expire.');
      }
    }
    
    const accessToken = await publicClientApplication.acquireTokenSilent({
      account: accounts[0],
      scopes: config.scope,
    }).then(response => {
      return response.accessToken;
    }).catch(error => {
      console.error('Access Token error:', error);
    });
    sessionStorage.setItem("accessToken", accessToken);
    ReactDOM.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      document.getElementById('root')
    );

  } else {
    const loginRequest = {
      scopes: config.scope,
      prompt: 'select_account',
    };
    await publicClientApplication.loginRedirect(loginRequest);
  }
}

checkLoginStatusAndRender();
