import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Switch, TextField } from "@mui/material";
import { styled } from "@mui/system";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ApiCall, GET, POST } from "../utils";
import "./providerLocationmapping.css"
import Spinner from 'react-bootstrap/Spinner';
import {
  GET_PROVIDERLOCATION_URL,
  POST_PROVIDERLOCATION_URL,
} from "../constants";

const StyledTableContainer = styled("div")({
  height: "100%",
  overflow: "auto",
});

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  margin: "10px",
  fontFamily: "Macklin Sans",
  overflow: "auto",
};

const headerStyle = {
  textAlign: "center",
  padding: "10px",
  marginTop: "70px",
};

function DataTable() {
  const [originalRows, setOriginalRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [isDataEdited, setIsDataEdited] = useState(false);
  const [modifiedRows, setModifiedRows] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);

  const columns = [
    { field: "providerFirstName", headerName: "First name", width: 160, headerClassName: "bold-header" },
    { field: "providerLastName", headerName: "Last name", width: 160, headerClassName: "bold-header" },
    { field: "locationOfPractice", headerName: "Location", width: 150, headerClassName: "bold-header custom-location-header" },
    {
      field: "isAcceptingPatient",
      headerName: "AcceptingPatients",
      width: 230,
      headerClassName: "bold-header",
      renderCell: (params) => (
        <Switch
          checked={params.row.isAcceptingPatient}
          onChange={(event) => handleSwitchChange(params.row.id, 'isAcceptingPatient', event.target.checked)}
        />
      ),
    },
    {
      field: "IsActive",
      headerName: "Active",
      width: 125,
      headerClassName: "bold-header",
      renderCell: (params) => (
        <Switch
        data-testid="switch-element"
          checked={params.row.isActive}
          onChange={(event) => handleSwitchChange(params.row.id, 'isActive', event.target.checked)}
          disabled={!isActive}
        />
      ),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiCall(GET_PROVIDERLOCATION_URL, null, GET, null, handleApiResponse);
        if (response?.status === 200) {
          const mappedData = response.data.map((item) => ({
            id: item.id,
            providerFirstName: item.providerFirstName,
            providerLastName: item.providerLastName,
            locationOfPractice: item.locationOfPractice,
            isActive: item.isActive,
            isAcceptingPatient: item.isAcceptingPatient,
          }));
          setOriginalRows(mappedData); 
          setRows(mappedData);
        }
      } catch (error) {
        console.error("Error fetching data from API:", error);
      }
      finally {
        setLoading(false); 
      }
    };

    fetchData();
  }, []);

  const handleApiResponse = (response) => {
    return response;
  };

  const handleSwitchChange = (rowId, fieldName, checked) => {
    const updatedRows = rows.map((row) =>
      row.id === rowId ? { ...row, [fieldName]: checked } : row
    );
  
    setRows(updatedRows);
    setIsDataEdited(true);
  
    // Track modified rows
    const modifiedRowIds = new Set(modifiedRows.map((row) => row.id));
    if (modifiedRowIds.has(rowId)) {
      // Row is already in the modifiedRows, update it
      const updatedModifiedRows = modifiedRows.map((row) =>
        row.id === rowId ? { ...row, [fieldName]: checked } : row
      );
      setModifiedRows(updatedModifiedRows);
    } else {
      // Row is not in the modifiedRows, add it
      const modifiedRow = rows.find((row) => row.id === rowId);
      setModifiedRows([...modifiedRows, { ...modifiedRow, [fieldName]: checked }]);
    }
  };

  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    const filteredData = originalRows.filter(
      (row) =>
        row.providerFirstName.toLowerCase().includes(searchTerm) ||
        row.providerLastName.toLowerCase().includes(searchTerm) ||
        row.locationOfPractice.toLowerCase().includes(searchTerm)
    );

    setRows(filteredData);
  };

  const handleSaveButtonClick = async () => {
    try {
      // Prepare the requestBody
      const requestBody = modifiedRows.map((row) => ({
        id: row.id,
        providerFirstName: row.providerFirstName,
        providerLastName: row.providerLastName,
        locationOfPractice: row.locationOfPractice,
        isActive: row.isActive,
        isAcceptingPatient: row.isAcceptingPatient,
        isEdited: true, // Add isEdited field
      }));
  
      // Send modifiedRows to the server or perform any other save operation
      console.log("Modified Rows:", requestBody);
  
      // Reset isDataEdited and modifiedRows
      setIsDataEdited(false);
      setModifiedRows([]);
  
      const response = await ApiCall(
        POST_PROVIDERLOCATION_URL,
        requestBody,
        POST,
        null,
        handleApiResponse
      );
  
      // Handle the API response
      if (response?.status === 200) {
        console.log("Save successful:", response.data);
        // You can perform additional actions after successful save
      }
       else if(response && response.status === 400) {
          setErrorMessage(response.data.message);
        }
      
      window.location.reload();
      window.location.href = "/providerlocationmapping";
    } catch (error) {
      console.error("Error while saving data:", error);
      // Handle the error or show a notification to the user
    }
  };
  
  return (
    <main data-testid="Grid-table">
    <div>
      {loading ? (
        <div data-testid="loading-spinner" className="text-center mt-3">
        <Spinner animation="border" role="status" className='spinner'
        style={{ width: "3rem", height: "3rem" }}>
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
      ) : (
        <div>
          <div style={headerStyle}>
            <h2 className="py-3 mb-1">Location Provider Mapping</h2>
          </div>

          <div className="search-and-add-container">
            <TextField
              placeholder="Search by Firstname, Lastname, or location"
              variant="outlined"
              onChange={handleSearch}
              fullWidth
              margin="normal"
              className="search-bar"
            />

            <div className="add-user-section">
              <div className="addUserGlyphicon">
                <a href="./addForm">
                  <FontAwesomeIcon icon={faUserPlus} />
                </a>
              </div>
              <div className="addUserText">
                <h3 className="h3-head">Add Mapping</h3>
              </div>
            </div>
          </div>

          <br />

          {/* Table Section */}
          <div style={containerStyle}>
            <StyledTableContainer>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
              />
            </StyledTableContainer>
          </div>

          <div className="save-button mt-2" data-testid="save">
            {isDataEdited && (
              <button data-testid="save" className="landing-start-btn" onClick={handleSaveButtonClick}>
                Save
              </button>
            )}
          </div>

          {errorMessage && <div className="error-message">{errorMessage}</div>}
        </div>
      )}
    </div>
    </main>
  );
}
export default DataTable;