import React, { useState,useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./Userlist.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { ApiCall, GET,PUT,POST } from '../../utils';
import { GET_APPUSER_URL,PUT_APPUSER_URL,POST_APPUSER_URL } from "../../constants";
import Switch from '@mui/material/Switch';
import Tooltip from "@mui/material/Tooltip";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

const UserList = () => {
  const [appUsers, setAppUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [userSwitches, setUserSwitches] = useState({});
  const [loading, setLoading] = useState(true);

  
  /* istanbul ignore next */
  const onDragEnd = async (result) => {
    const { source, destination } = result;
  
    if (!destination) {
      return;
    }
  
    setLoading(true);
  
    try {
      if (source.droppableId === destination.droppableId) {
        const reorderedItems = reorder(
          source.droppableId === 'users' ? users : appUsers,
          source.index,
          destination.index
        );
  
        if (source.droppableId === 'users') {
          setUsers(reorderedItems);
        } else {
          setAppUsers(reorderedItems);
        }
      } else {
        const { users: updatedUsers, appUsers: updatedAppUsers } = await move(source, destination);
        setUsers(updatedUsers);
        setAppUsers(updatedAppUsers);
      }
    } catch (error) {
      console.error('Error updating lists:', error);
    } finally {
      setLoading(false);
    };
  };
  
  /* istanbul ignore next */
   const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const move = async (source, destination) => {
    const sourceClone = Array.from(
      source.droppableId === 'users' ? users : appUsers
    );
    const destClone = Array.from(
      destination.droppableId === 'users' ? users : appUsers
    );
  
    const [removed] = sourceClone.splice(source.index, 1);
    const movedUser = { ...removed, selectedOption: '' };
  
    destClone.splice(destination.index, 0, movedUser);
  
    if (source.droppableId === 'users' && destination.droppableId === 'appUsers') {
      try {
        setLoading(true);
        const response = await ApiCall(
          POST_APPUSER_URL,
          {
            userPrincipalId: movedUser.id,
            emailAddress: movedUser.emailid,
            role: userSwitches[movedUser.id] ? 'Administrator' : 'Viewer',
          },
          POST,
          null,
          handleApiResponse
        );
  
        if (response?.status === 200) {
          console.log('User added to App Users successfully:', response.data);
  
          
          setAppUsers(prevAppUsers => {
            const updatedAppUsers = [...prevAppUsers, {
              id: response.data.id,
              content: response.data.principalDisplayName,
              role: userSwitches[movedUser.id] ? 'Administrator' : 'Viewer',
              emailId: movedUser.emailAddress,
            }];
            return updatedAppUsers;
          });
  
          return {
          users: source.droppableId === 'users' ? sourceClone : users,
          appUsers: destination.droppableId === 'appUsers' ? [...appUsers,{
            id: response.data.id,
            content: response.data.principalDisplayName,
            role: userSwitches[movedUser.id] ? 'Administrator' : 'Viewer',
            emailId: movedUser.emailAddress,
          }] : appUsers,
        };
        } else {
          console.error('Error adding user to App Users:', response);
        }
      } catch (error) {
        console.error('Error adding user to App Users:', error);
      }
      finally {
        setLoading(false);
      }
    }
  
    return {
      users: source.droppableId === 'users' ? sourceClone : users,
      appUsers: destination.droppableId === 'appUsers' ? destClone : appUsers,
    };
  };
  
  const handleSwitchChange = (userId) => {
    setUserSwitches((prevSwitches) => ({
      ...prevSwitches,
      [userId]: !prevSwitches[userId],
    }));
  };

  const grid = 2;

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    background:  "white",
    ...draggableStyle,
  });
  

  const getListStyle = (isDraggingOver) => ({
    background:  "white",
    padding: grid,
    borderRadius: "5px",
    width: "100%",
  });

  const filteredUsers = users && users.filter((item) =>
  item && item.content.toLowerCase().includes(searchQuery.toLowerCase())
);

  
const fetchAppUsers = async () => {
    try {
      const response = await ApiCall(GET_APPUSER_URL, null, GET, null, handleApiResponse);
      if (response?.status === 200) {
        const jsondata = response.data;
        const appusers = jsondata.map(item => ({
          id: item.appRoleAssignment.id,
          content: item.appRoleAssignment.principalDisplayName,
          role: item.role,
          emailId: item.emailId,
      }));
        console.log('appusers', appusers);
        setAppUsers(appusers);
        setLoading(false); 
        return appusers;
      }
    } catch (error) {
      console.error('Error fetching appUsers:', error);
      setLoading(false); 
    }
    return [];
  };

  useEffect(() => {
    fetchAppUsers();
  }, []);
  
  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    const filteredUsers = users.filter(
      (user) => user.content.toLowerCase().includes(query.toLowerCase())
    );
    setUsers(filteredUsers);
  };

  const handleApiResponse = (response) => {
    return response;
  };
  
  useEffect(() => {
    const fetchAllUsers = async () => {
        setLoading(true);
      try {
        const alluserapi = `allusers?displayName=${searchQuery}`;
        const response = await ApiCall(alluserapi, null, 'GET', null, handleApiResponse);
        if (response?.status === 200) {
          const jsonData = response.data;
  
          console.log('API Response:', jsonData);
          const searcheduserdata = jsonData.currentPage.map(user => {
            if (!appUsers || !appUsers.find(appUser => appUser.content === user.displayName)) {

              return {
                id: user.id, 
                content: user.displayName, 
                emailid: user.userPrincipalName, 
                emailAddress:user.mail
              };
            }
            return null; 
          }).filter(Boolean); 
  
          setUsers(searcheduserdata);
          console.log('searcheduserdata:', searcheduserdata);
        }
      } catch (error) {
        console.error('Error fetching allUsers:', error);
      }
      finally {
        setLoading(false); 
      }
    };
  
    fetchAllUsers();
  }, [searchQuery, appUsers]); 
  

  const handleDeleteAppUser = async (userId, emailId) => {
    try {
      const deleteResponse = await ApiCall(
        PUT_APPUSER_URL,
        { appRoleId: userId, emailAddress: emailId },
        PUT,
        null,
        handleApiResponse
      );
  
      if (deleteResponse?.status === 200) {
        setAppUsers((prevAppUsers) => {
          const updatedAppUsers = prevAppUsers.filter((user) => user.id !== userId);
          return updatedAppUsers;
        });
      } else {
        console.error('Error deleting app user:', deleteResponse);
      }
    } catch (error) {
      console.error('Error deleting app user:', error);
    }
  };
  

  return (
    <main data-testid="user-switch-1">
    <div data-testid="userlist-component" >
      <h2 className="h2heading">Add User</h2>
      </div>
    <div className="appuser center">
      <div id="app-container">
      {loading && (
          <div className="loading-indicator-container">
            <div className="loading-indicator">
              <div className="spinner"></div>
            </div>
          </div>
        )}
        <div className="column-container">
        <DragDropContext onDragEnd={onDragEnd}>
        <div className="column">
  <div className="Usercard">
    <h3>Users</h3>
    <input
      type="text"
      placeholder="Search User"
      value={searchQuery}
       onChange={handleSearch}
      style={{ borderRadius: '20px', padding: '8px',height:"45px",marginTop:"10px" }}
    />
    <Droppable droppableId="users">
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
           {...provided.droppableProps}
          style={getListStyle(snapshot.isDraggingOver)}
        >
          {filteredUsers.length === 0 && searchQuery.length > 0 ? (
            <div style={{ textAlign: 'center', color: 'gray', marginTop: '20px' }}>
              User not found.
            </div>
          ) : (
            filteredUsers.map((item, index) => {
              return (
                <Draggable
                  key={item.id}
                  draggableId={item.id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <div data-testid="user-switch-1"
                        className="item"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          position: "relative",
                        }}
                      >
                        <span>{item.content}</span>
                        <Tooltip title="User Role" arrow>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {userSwitches[item.id] ? (
                              <span
                                style={{
                                  color: "#264E5A",
                                  marginRight: "5px",
                                }}
                              >
                                Admin
                              </span>
                            ) : (
                              <span
                                style={{
                                  color: "#FEB52B",
                                  marginRight: "5px",
                                }}
                              >
                                Viewer
                              </span>
                            )}
                           
                            <Switch  data-testid="switch" 
                              checked={userSwitches[item.id] || false}
                              onChange={() => handleSwitchChange(item.id)}
                            />
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  )}
                </Draggable>
              );
            })
          )}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  </div>
</div>

<div className="column">
  <div className="Usercard">
    <h3>App Users</h3>
    <Droppable droppableId="appUsers">
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          style={getListStyle(snapshot.isDraggingOver)}
        >
           <div className="dotted-box">
                          Drag and Drop Here
                        </div>
                        <div style={{marginTop:"10px"}}>
            {appUsers.map((item, index) => (
              <Draggable
                key={item.id}
                draggableId={item.id}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <div className="item">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        marginBottom:"8px",
                        marginTop:"6.5px"
                     
                        }}
                      >
                        <span>{item.content}</span>
                        <span style={{ color: "grey", marginLeft: "auto",marginRight: "10px" }}>
                        { (item.role === "Administrator" ? "Admin" : "Viewer")}
                        </span>
                    
                        <DeleteOutlinedIcon data-testid="delete-icon"
                          style={{ cursor: "pointer", color: "grey" }}
                          onClick={() =>
                            handleDeleteAppUser(item.id, item.emailId)
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
          </div>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  </div>
</div>
          </DragDropContext>
        </div>
      </div>
      </div>
      </main>
  );
}
export default UserList;