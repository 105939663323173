import React, { useState, useEffect } from "react";
import "./addForm.css";
import { ApiCall, GET, POST } from "../utils";
import { POST_PROVIDERLOCATION_URL,GET_DROPDOWNPROVIDERS_URL } from "../constants";
import { useNavigate } from 'react-router-dom';
import Select from "react-select";

// NOSONAR
function MyForm() {
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [firstNamesDropdown, setFirstNamesDropdown] = useState([]);
  const [lastNamesDropdown, setLastNamesDropdown] = useState([]);
  const [locationsDropdown, setLocationsDropdown] = useState([]);
  const [selectedFirstName, setSelectedFirstName] = useState("");
  const [selectedLastName, setSelectedLastName] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [fullNamesDropdown, setFullNamesDropdown] = useState([]);

  let namesArray = [];
/* istanbul ignore next */
  if (firstNamesDropdown.length === lastNamesDropdown.length) {
    for (let i = 0; i < firstNamesDropdown.length; i++) {
      const firstName = firstNamesDropdown[i];
      const lastName = lastNamesDropdown[i];
      const fullName = `${firstName} ${lastName}`;
      namesArray.push(fullName);
    }
  } else {
    console.error(
      "Arrays firstNamesDropdown and lastNamesDropdown must have the same length."
    );
  }

 /* istanbul ignore next */
  // NOSONAR
  useEffect(() => {
    
    const fetchDropdownOptions = async () => {
      try {
        await ApiCall(
          GET_DROPDOWNPROVIDERS_URL,
          null,
          GET,
          null,
          function (response) {
            if (response?.status === 200) {
              const options = response.data.nextGenProviders;
              const firstNames = options.map((option) => option.firstName);
              const lastNames = options.map((option) => option.lastName);
              const locations = response.data.clinicLocations.map(
                (location) => location.name
              );
              const fullName = firstNames.map(
                (firstName, index) => `${firstName} ${lastNames[index]}`
              );
              const ids = options.map((option) => option.id);

              setLocationsDropdown(locations);
              setFirstNamesDropdown(firstNames);
              setLastNamesDropdown(lastNames);
              setFullNamesDropdown(fullName);
            }
          }
        );
      } catch (error) {
        console.error("Error fetching data from API:", error);
      }
    };
    fetchDropdownOptions();
  }, []);

 /* istanbul ignore next */
 const handleSubmit = async (e) => {
  e.preventDefault();

  const formData = {
    providerFirstName: selectedFirstName,
    providerLastName: selectedLastName,
    locationOfPractice: selectedLocation,
    isActive: true,
    isAcceptingPatient:true,
    isEdited: true,
  };

  try {
    const response = await ApiCall(
      POST_PROVIDERLOCATION_URL,
      [formData],
      POST,
      null,
      handleApiResponse
    );
    if(response.status === 200){  
      navigate("../providerlocationmapping");
    }
    else if (response.status === 400) {
      setErrorMessage(response.data.message);
    }
  } catch (error) {
    console.log("An error occurred while submitting the form:", error);
  }
};

  const handleApiResponse = (response) => {
    return response;
  };


  return (
    <main data-testid="MyFormElement">
      <div className="landing-main-heading">
        <h2 className="text-center py-2 mb-2">Add Location Provider Mapping</h2>
      </div>

      <div className="form-container">
        <form
          id="enrollmentemp"
          className="needs-validation landing-form mb-5"
          onSubmit={handleSubmit}
        >
          
          <div className="provider-dropdown-container">
  <label className="formLabel">
  Provider Name
    <div  >
    <Select
                  options={fullNamesDropdown.map((fullName) => ({
                    value: fullName,
                    label: fullName,
                  }))}
                  onChange={(selectedOption) => {
                    const selectedName = selectedOption.value;
                    const [providerFirstName, providerLastName,...rest] = selectedName.split(" ");
                    setSelectedFirstName(providerFirstName);
                    setSelectedLastName(providerLastName+ " " + rest.join(" "));
                  }}
                  className="dropdown-option"
                  classNamePrefix="react-select"
                  placeholder="Select Name" 
                  aria-label="Select practitioner name" 
                />
    </div>
  </label>
  
  <label className="formLabel-1">
              Location of Practice
              
              <div>
                <Select
                  options={locationsDropdown.map((location) => ({
                    value: location,
                    label: location,
                  }))}
                  onChange={(selectedOption) => setSelectedLocation(selectedOption.value)}
                  className="dropdown-option"
                  classNamePrefix="react-select"
                  placeholder="Select Location" 
                  aria-label="Select practitioner location" 
                />
              </div>
            </label>

 
</div>
       
          {errorMessage && (
          <div className="patient-info-snack-msg">
            <span>{errorMessage}</span>
          </div>
        )}
          <div className="button-container">
            <div className="landing-button">
              <button className="landing-start-btn" type="submit">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </main>
  );
}

export default MyForm;
