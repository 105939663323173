import React from "react";
import HeaderLogo from "../../assets/images/header.png";
import "./Header.css";
import Sidebar from "../../containers/dashboard/Sidebar";
import PropTypes from "prop-types";


function Header({ showSidebar,isErrorPage}) {
  return (
    <div className={`header-section ${showSidebar ? "open" : ""}`}data-testid="mocked-header">
      <img src={HeaderLogo} alt="logo" width={228} height={61} />
      {!isErrorPage && <Sidebar />}
    </div>
  );
}
Header.propTypes = {  showSidebar: PropTypes.string, isErrorPage: PropTypes.bool}
  
export default Header;
