import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ErrorPage.css';


const ErrorPage = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    // Clear localStorage
    localStorage.clear();

    // Clear sessionStorage
    sessionStorage.clear();

    // Clear cookies
    document.cookie.split(';').forEach(function (c) {
      document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });

    // Navigate to the previous page
    navigate(-2);
  };



  return (
    <div data-testid="error-component"className="error-page-container">
      {/* Card Component */}
      <div className="errorcard">
        <div className="errorcard-header">
        </div>
        <div className="errorcard-body">
         
          
           
            It seems like the user with the provided email doesn't have access.
            Please get in touch with the administrator to grant access.
        
          
          </div>
          <button type="button" className="back-btn" onClick={handleBack} data-testid="back-btn">
            Back to Login 
          </button>
        
      </div>
    </div>
  );
};

export default ErrorPage;
