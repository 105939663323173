import axios from "axios";
import { GET, POST, PUT } from ".";
import { authResponse } from "../constants";
import { BASE_URL } from "../constants/urlEndpoint";

export const ApiCall = async (url, payload, method, tokenItem,fnFunction ,headerObject,baseUrl = BASE_URL) => {
    
    const token = sessionStorage.getItem('accessToken')

    
    const headers = {
        sourceFrom:"cmdFrontend",
        transactionID:new Date().getTime(),
        clientID:"cmdFrontend",
        requestedDateTime:new Date().toISOString(),
        accept: "application/json",
    }
    if (token !== null) headers.Authorization = `Bearer ${token}`;
    if(tokenItem !==null) headers.recaptchaKey = `${tokenItem}`;
    try {
        let res ;
        if (method === GET) res = await axios.get(`${baseUrl}${url}`,{ headers:headers });
        else if (method === POST) res = await axios.post(`${baseUrl}${url}`, payload, { headers });
        else if (method === PUT) res = await axios.put(`${baseUrl}${url}`, payload, { headers });
        return fnFunction(res);
    } catch (error) {    
       
        return fnFunction(error.response);
    }
}

export const httpReq = async (url, tokenItem) => {
    const tokenReq = await authResponse();
    const token = await tokenReq.data.access_token;
    const headers = { sourceFrom:"cmdFrontend", transactionID: new Date().getTime(), clientID: "cmdFrontend", requestedDateTime: new Date().toISOString(), accept: "application/json", };
    if (token !== null) headers.Authorization = `Bearer ${token}`;
    if (tokenItem !== null) headers.recaptchaKey = `${tokenItem}`;
    try { return await axios.get(`${BASE_URL}${url}`, { headers }); }
    catch (e) { return e.response; }
}