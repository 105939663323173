export const START_DATE = "2/14/2023";
export const END_DATE = "2/15/2023";
export const [ENROLLED, ABANDONED, PENDING, ALL_ENROLLED, COMPLETED] = ["ENROLLED", "ABANDONED", "PENDING", "ALL ENROLLED", "COMPLETED"];
export const [IN_PROGRESS, DONE, REJECTED] = ["IN PROGRESS", "DONE", "REJECTED"];
export const getPieChartFlowConfig = (data) => ({ type: 'doughnut', data });
export const chartOptions = {
    maintainAspectRatio: true,
    aspectRatio: 1,
    responsive: true,
};
export const getPieChartFlowSetup = (data) => {
    return {
        labels: [ABANDONED, ENROLLED, PENDING],
        datasets: [{
            label: 'Patients by flow status',
            data,
            backgroundColor: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)'],
            hoverOffset: 4
        }]
    }
}

const data = [
    {
        name: "Prim 1",
        enrollmentStatus: ENROLLED,
        location: "Portland",
        date: "2/14/2023",
        paymentStatus: IN_PROGRESS
    },
    {
        name: "Prim 2",
        enrollmentStatus: PENDING,
        location: "Portsmouth",
        date: "2/15/2023",
        paymentStatus: DONE
    },
    {
        name: "Prim 3",
        enrollmentStatus: ABANDONED,
        location: "Portland",
        date: "2/14/2023",
        paymentStatus: REJECTED
    },
    {
        name: "Dep 1",
        enrollmentStatus: ENROLLED,
        location: "Portsmouth",
        date: "2/15/2023",
        paymentStatus: IN_PROGRESS
    },
    {
        name: "Dep 2",
        enrollmentStatus: PENDING,
        location: "Portland",
        date: "2/14/2023",
        paymentStatus: DONE
    },
    {
        name: "Dep 3",
        enrollmentStatus: ABANDONED,
        location: "Portsmouth",
        date: "2/15/2023",
        paymentStatus: REJECTED
    },
    {
        name: "Spouse 1",
        enrollmentStatus: ENROLLED,
        location: "Portland",
        date: "2/14/2023",
        paymentStatus: IN_PROGRESS
    },
    {
        name: "Spouse 2",
        enrollmentStatus: PENDING,
        location: "Portsmouth",
        date: "2/15/2023",
        paymentStatus: DONE
    },
    {
        name: "Spouse 3",
        enrollmentStatus: ABANDONED,
        location: "Portland",
        date: "2/14/2023",
        paymentStatus: REJECTED
    },
];

export default data;