import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { Row, Col } from 'react-bootstrap';
import './Dashboard.css';
import Chart from 'chart.js/auto';
import { ABANDONED, PENDING, COMPLETED } from './data';
import { ApiCall, GET } from '../../utils';
import { GET_DASHBOARDSTATS_URL, GET_DOWNLOAD_URL } from "../../constants";
import Zoom from 'chartjs-plugin-zoom';
import Spinner from 'react-bootstrap/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';
import Papa from 'papaparse';
import { useNavigate } from 'react-router-dom';

function Dashboard() {

  const [chartData, setChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [downloadApiResponse, setDownloadApiResponse] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false); 
  const [isVisibleH2, setIsVisibleH2] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));

 
  const navigate = useNavigate();


  const [enrollmentChart, setEnrollmentChart] = useState(null);
  const [locationChart, setLocationChart] = useState(null);
  const [paymentChart, setPaymentChart] = useState(null);
  const [paymentChart4, setPaymentChart4] = useState(null);
  const [paymentChart5, setPaymentChart5] = useState(null);
  const [paymentChart6, setPaymentChart6] = useState(null);
  const [paymentChart7, setPaymentChart7] = useState(null);
  const [paymentChart8, setPaymentChart8] = useState(null);
  const [paymentChart9, setPaymentChart9] = useState(null);

  // CHARTS SHOWN
  const [isChart1Shown, setIsChart1Shown] = useState(false);
  const [isChart2Shown, setIsChart2Shown] = useState(false);
  const [isChart3Shown, setIsChart3Shown] = useState(false);
  const [isChart4Shown, setIsChart4Shown] = useState(false);
  const [isChart5Shown, setIsChart5Shown] = useState(false);
  const [isChart6Shown, setIsChart6Shown] = useState(false);
  const [isChart7Shown, setIsChart7Shown] = useState(false);
  const [isChart8Shown, setIsChart8Shown] = useState(false);
  const [isChart9Shown, setIsChart9Shown] = useState(false);

  /* istanbul ignore next */
  const setChart = (enrollmentArray, locationArray, paymentArray, paymentArray4, paymentArray5, paymentArray6, paymentArray7, paymentArray8, paymentArray9) => {
    const enrollmentCount = document.getElementById('patientsByStatus').getContext('2d');
    const weekCompCompleted = document.getElementById('patientsByLocation').getContext('2d');
    const weekCompAbandoned = document.getElementById('patientsByPaymentStatus').getContext('2d');
    const weekPercProgress = document.getElementById('patientsByPaymentStatus4').getContext('2d');
    const monthCompCompleted = document.getElementById('patientsByPaymentStatus5').getContext('2d');
    const monthCompAbandoned = document.getElementById('patientsByPaymentStatus6').getContext('2d');
    const dailyEnrollmentCount = document.getElementById('patientsByPaymentStatus7').getContext('2d');
    const locationWiseCountweekly = document.getElementById('patientsByPaymentStatus8').getContext('2d');
    const locationWiseCountMonthly = document.getElementById('patientsByPaymentStatus9').getContext('2d');

    if (enrollmentChart || locationChart || paymentChart || paymentChart4 || paymentChart5 || paymentChart6 || paymentChart7 || paymentChart8 || paymentChart9) {
      locationChart.destroy();
      enrollmentChart.destroy();
      paymentChart.destroy();
      paymentChart4.destroy();
      paymentChart5.destroy();
      paymentChart6.destroy();
      paymentChart7.destroy();
      paymentChart8.destroy();
      paymentChart9.destroy();


    }

    let enrollmentCountObject = new Chart(enrollmentCount, {
      type: 'doughnut',
      data: enrollmentArray,
      options: { plugins: { legend: { position: 'bottom' } } }
    });
    let weekCompCompletedObject = new Chart(weekCompCompleted, {
      type: 'bar',
      data: locationArray,
      options: chartOptionsWithoutGridLines
    });
    let weekCompAbandonedObject = new Chart(weekCompAbandoned, {
      type: 'bar',
      data: paymentArray,
      options: chartOptionsWithoutGridLines
    });
    //find an replacewith meaningful labels
    let weekPercProgressObject = new Chart(weekPercProgress, {
      type: 'bar',
      data: paymentArray4,
      options: chartOptionsWithoutGridLines
    });
    let monthCompCompletedObject = new Chart(monthCompCompleted, {
      type: 'bar',
      data: paymentArray5,
      options: chartOptionsWithoutGridLines
    });
    let monthCompAbandonedObject = new Chart(monthCompAbandoned, {
      type: 'bar',
      data: paymentArray6,
      options: chartOptionsWithoutGridLines
    });
    let dailyEnrollmentCountObject = new Chart(dailyEnrollmentCount, {
      type: 'bar',
      data: paymentArray7,
      options: chartOptionsWithoutGridLines
    });
    let locationWiseCountweeklyObject = new Chart(locationWiseCountweekly, {
      type: 'bar',
      data: paymentArray8,
      options: chartOptionsWithoutGridLines
    });
    let locationWiseCountMonthlyObject = new Chart(locationWiseCountMonthly, {
      type: 'bar',
      data: paymentArray9,
      options: chartOptionsWithoutGridLines
    });

    setEnrollmentChart(enrollmentCountObject);
    setLocationChart(weekCompCompletedObject);
    setPaymentChart(weekCompAbandonedObject);
    setPaymentChart4(weekPercProgressObject);
    setPaymentChart5(monthCompCompletedObject);
    setPaymentChart6(monthCompAbandonedObject);
    setPaymentChart7(dailyEnrollmentCountObject);
    setPaymentChart8(locationWiseCountweeklyObject);
    setPaymentChart9(locationWiseCountMonthlyObject);
  };
  const chartOptionsWithoutGridLines = {
    maintainAspectRatio: false,
    aspectRatio: 1,
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
      zoom: {
        pan: {
          enabled: true,
          mode: "x",
          speed: 1,
          threshold: 1,
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          mode: 'x',
        },
        pinch: {
          enabled: true,
        }
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        min: 0,
        stretch: true,

      },
      y: {
        grid: {
          display: false,
        },
      },
    },
  };

  /* istanbul ignore next */
  const handleIconClick = async () => {
    if (!downloadApiResponse || isDownloading) {
      return;
    }

    try {
      setIsDownloading(true);
      const byteArray = Uint8Array.from(window.atob(downloadApiResponse.data), c => c.charCodeAt(0));
      const jsonString = new TextDecoder().decode(byteArray);
      const parsedObject = JSON.parse(jsonString);
      const csvString = Papa.unparse(parsedObject);
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'Patient_Data.csv';
      document.body.appendChild(link);
      link.click();
      await new Promise(resolve => setTimeout(resolve, 1000));
      URL.revokeObjectURL(url);
      document.body.removeChild(link);
      setIsDownloading(false);
    } catch (error) {
      console.error('Error in handleIconClick:', error);
      setIsDownloading(false);
    }
  };

  /* istanbul ignore next */
  useEffect(() => {  
    const fetchChartData = async () => {
      try {
        await ApiCall(GET_DASHBOARDSTATS_URL, null, GET, null, function (response) {
          if (response?.status === 200) {
            const jsonData = response.data;
            const userDetails = jsonData.userDetails;
          const responseJsonString = JSON.stringify(userDetails);
          console.log("userDetails", responseJsonString);
          
          localStorage.setItem("userDetails", responseJsonString);

            const dayIds = jsonData.dayIds;
            const weeklyLabels = jsonData.weekIDs;
            const monthlyLabels = jsonData.monthIDs;

            //chart1
            let completedCount = jsonData.enrollmentCount[0];
            let pendingCount = jsonData.enrollmentCount[1];
            let abandonedCount = jsonData.enrollmentCount[2];

            //Chart2
            const UrgentCareWeeklyTotalCompleted = jsonData.urgentCareWeeklyTotalCompleted;
            const NormalFlowWeeklyTotalCompleted = jsonData.normalFlowWeeklyTotalCompleted;

            //chart3
            const UrgentCareWeeklyTotalAbandoned = jsonData.urgentCareWeeklyTotalAbandoned;
            const NormalFlowWeeklyTotalAbandoned = jsonData.normalFlowWeeklyTotalAbandoned;

            //chart4
            const UrgentCareweekPercProgress = jsonData.urgentCareWeeklyCompletedEnrollmentsPercentageChange;
            const NormalCareweekPercProgress = jsonData.normalFlowWeeklyCompletedEnrollmentsPercentageChange;

            //chart6
            const UrgentCareMonthlyTotalAbandoned = jsonData.urgentCareMonthlyTotalAbandoned;
            const NormalFlowMonthlyTotalAbandoned = jsonData.normalFlowMonthlyTotalAbandoned;



            //chart 5
            const UrgentCareMonthlyTotalCompleted = jsonData.urgentCareMonthlyTotalCompleted;
            const NormalFlowMonthlyTotalCompleted = jsonData.normalFlowMonthlyTotalCompleted;

            //chart 7

            const NormalFlowDailyCompletedEnrollmentCount = jsonData.normalFlowDailyCompletedEnrollmentCount;
            const UgentCareDailyCompletedEnrollmentCount = jsonData.urgentCareDailyCompletedEnrollmentCount;

            //chart8
            const urgentCareWeeklyLocations = jsonData.urgentCareWeeklyLocations;
            const urgentCareLocationWiseWeeklyCount = jsonData.urgentCareLocationWiseWeeklyCount;
            const previousWeekCounts = urgentCareLocationWiseWeeklyCount.map(item => item[0]);
            const currentWeekCounts = urgentCareLocationWiseWeeklyCount.map(item => item[1]);
            //chart9
            const urgentCareMonthlyLocations = jsonData.urgentCareMonthlyLocations;
            const urgentCareLocationWiseMonthlyCount = jsonData.urgentCareLocationWiseMonthlyCount;
            const previousMonthCounts = urgentCareLocationWiseMonthlyCount.map(item => item[0]);
            const currentMonthCounts = urgentCareLocationWiseMonthlyCount.map(item => item[1]);

            // CHART 1
            const enrollmentCountData = {
              labels: [
                `${COMPLETED} `,
                `${PENDING} `,
                `${ABANDONED} `
              ],
              datasets: [{
                data: [completedCount, pendingCount, abandonedCount],
                backgroundColor: ['#264E54', '#9DAFB5', '#FEB52B'],
                hoverOffset: 3
              }],

            }

            // CHART 2
            const weekCompCompletedData = {
              labels: weeklyLabels,
              datasets: [
                {
                  label: 'Urgent Care Enrollments',
                  data: UrgentCareWeeklyTotalCompleted,
                  backgroundColor: ['#FEB52B'],
                  hoverOffset: 4
                },

                {
                  label: 'Non-UC Enrollments',
                  data: NormalFlowWeeklyTotalCompleted,
                  backgroundColor: ['#264E54'],
                  hoverOffset: 4
                }
              ],

            };

            // CHART 3
            const weekCompAbandonedData = {
              labels: weeklyLabels,
              datasets: [
                {
                  label: 'Urgent Care Enrollments',
                  data: UrgentCareWeeklyTotalAbandoned,
                  backgroundColor: ['#FEB52B'],
                  hoverOffset: 4
                },
                {
                  label: 'Non-UC Enrollments',
                  data: NormalFlowWeeklyTotalAbandoned,
                  backgroundColor: ['#264E54'],
                  hoverOffset: 4
                }
              ],
            };

            //chart4
            const weekPercProgressData = {
              labels: weeklyLabels.slice(1),
              datasets: [{
                label: 'Urgent Care Enrollments',
                data: UrgentCareweekPercProgress,
                backgroundColor: ['#FEB52B'],
                hoverOffset: 90
              },
              {
                label: 'Non-UC Enrollments',
                data: NormalCareweekPercProgress,
                backgroundColor: ['#264E54'],
                hoverOffset: 90
              }],
            }

            // CHART 5
            const monthCompCompletedData = {
              labels: monthlyLabels,
              datasets: [
                {
                  label: 'Urgent Care Enrollments',
                  data: UrgentCareMonthlyTotalCompleted,
                  backgroundColor: ['#FEB52B'],
                  hoverOffset: 90
                },

                {
                  label: 'Non-UC Enrollments',
                  data: NormalFlowMonthlyTotalCompleted,
                  backgroundColor: ['#264E54'],
                  hoverOffset: 90
                }
              ],
            };


            // CHART 6
            const monthCompAbandonedData = {
              labels: monthlyLabels,
              datasets: [
                {
                  label: 'Urgent Care Enrollments',
                  data: UrgentCareMonthlyTotalAbandoned,
                  backgroundColor: ['#FEB52B'],
                  hoverOffset: 4
                },
                {
                  label: 'Non-UC Enrollments',
                  data: NormalFlowMonthlyTotalAbandoned,
                  backgroundColor: ['#264E54'],
                  hoverOffset: 4
                }
              ],
            };

            //chart7             
            const dailyEnrollmentCountData = {
              labels: dayIds,
              datasets: [

                {
                  label: 'Urgent Care Enrollments',
                  data: UgentCareDailyCompletedEnrollmentCount,
                  backgroundColor: ['#FEB52B'],
                  hoverOffset: 4
                },
                {
                  label: 'Non-UC Enrollments',
                  data: NormalFlowDailyCompletedEnrollmentCount,
                  backgroundColor: ['#264E54'],
                  hoverOffset: 4
                }
              ],
            };

            //chart8             
            const urgentCareWeeklyEnrollmentCountData = {
              labels: [...urgentCareWeeklyLocations],
              datasets: [

                {
                  label: 'Previous Week',
                  data: previousWeekCounts,
                  backgroundColor: ['#FEB52B'],
                  hoverOffset: 4
                },
                {
                  label: 'Current Week',
                  data: currentWeekCounts,
                  backgroundColor: ['#264E54'],
                  hoverOffset: 4
                }
              ],
            };
            //chart9   

            const urgentCareMonthlyEnrollmentCountData = {
              labels: [...urgentCareMonthlyLocations],
              datasets: [

                {
                  label: 'Previous Month',
                  data: previousMonthCounts,
                  backgroundColor: ['#FEB52B'],
                  hoverOffset: 4
                },
                {
                  label: 'Current Month',
                  data: currentMonthCounts,
                  backgroundColor: ['#264E54'],
                  hoverOffset: 4
                }
              ],

            };

            Chart.register(Zoom);

            setChart(enrollmentCountData, weekCompCompletedData, weekCompAbandonedData, weekPercProgressData, monthCompCompletedData, monthCompAbandonedData, dailyEnrollmentCountData, urgentCareWeeklyEnrollmentCountData, urgentCareMonthlyEnrollmentCountData);

            setIsChart1Shown(true);
            setIsChart2Shown(true);
            setIsChart3Shown(true);
            setIsChart4Shown(true);
            setIsChart5Shown(true);
            setIsChart6Shown(true);
            setIsChart7Shown(true);
            setIsChart8Shown(true);
            setIsChart9Shown(true);
            setIsVisibleH2(true);

            setChartData(...fetchChartData);
          }else if (response?.status !== 200) {
            // Handle 401 status here
            const errorMsg = response.data?.message || 'Unauthorized';
            console.error('API Error:', errorMsg);
            navigate('/errorpage');
          }
                  });
      }
      catch (error) {
        console.error('Error fetching chart data:', error);
            }
            finally {
              setIsLoading(false); 
            }
    };
    if (!chartData) {
      fetchChartData();
    }
  }, [chartData]);

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (userDetails && userDetails.role === 'Administrator') {
      const fetchDownloadUrl = async () => {
        try {
                    const downloadapiresponse = await ApiCall(GET_DOWNLOAD_URL, null, 'GET', null, handleApiResponse);
          setDownloadApiResponse(downloadapiresponse);
                  } catch (error) {
          console.error('Error fetching download URL:', error);
                }
      };
  
      fetchDownloadUrl();
    }
  
  }, []);

  const handleApiResponse = (response) => {
    setIsDownloading(false);
    return response;
  };
 
  return (
    <main data-testid="dashboard-component" className="mb-5 chartPageCss">
       {isLoading && (
      <div data-testid="loading-spinner" className="text-center mt-3">
        <Spinner animation="border" role="status" className='spinner'
        style={{ width: "3rem", height: "3rem" }}>
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    )}
          {isVisibleH2 && (
            <div>
        <h2 className="text-center"> Enrollment Dashboard</h2>
        {userDetails && userDetails.role === 'Administrator' && (
          <div data-testid="download-icon" className='download-icon-dash'>
            <FontAwesomeIcon
              icon={faFileArrowDown}
              onClick={handleIconClick}
              className={isDownloading || !downloadApiResponse ? 'disabled-icon' : ''}
            />
          </div>
        )} 
        </div>  
      )}
      
          
<div className='container-card'>
        <Container fluid>
          <Row md={4} m={12} className="d-flex">
            <Col md={4}>
              <article className={`p-2 ${isChart1Shown ? 'card shadow cardCss' : ''}`}>
                {isChart1Shown && (
                  <h4 className="card-title pt-2 mb-0 text-center chart-title">Enrollment Count</h4>
                )}
                <div data-testid="patientsByStatus" className={`status-patients-container ${isChart1Shown ? 'card-body' : ''}`} >
                  <canvas id="patientsByStatus" ></canvas>
                </div>
              </article>
            </Col>
            <Col md={4}>

              <article className={`p-2 ${isChart2Shown ? 'card shadow cardCss' : ''}`}>
                {isChart2Shown && <h4 className="card-title pt-2 mb-0 text-center chart-title">Week On Week Comparison (Completed)</h4>}
                <div data-testid="patientsByLocation" className={`location-patients-container ${isChart2Shown ? 'card-body' : ''}`}>
                  <canvas id="patientsByLocation" ></canvas>
                </div>
              </article>
            </Col>
            <Col md={4}>
              <article className={`p-2 ${isChart3Shown ? 'card shadow cardCss' : ''}`}>
                {isChart3Shown && <h4 className="card-title pt-2 mb-0 text-center chart-title">Week On Week Comparison (Abandoned)</h4>}
                <div data-testid="patientsByPaymentStatus" className={`payment-patients-container ${isChart3Shown ? 'card-body' : ''}`}>
                  <canvas id="patientsByPaymentStatus" ></canvas>
                </div>
              </article>
            </Col>
          </Row>
        </Container>

        <Container fluid>
          <Row md={4} m={12} className="d-flex">
            <Col md={4}>
              <article className={`p-2 ${isChart4Shown ? 'card shadow cardCss' : ''}`}>
                {isChart4Shown && <h4 className="card-title pt-2 mb-0 text-center chart-title">Week On Week % Progress</h4>}
                <div data-testid="patientsByPaymentStatus4" className={`status-patients-container ${isChart4Shown ? 'card-body' : ''}`}>
                  <canvas id="patientsByPaymentStatus4"></canvas>
                </div>
              </article>
            </Col>
            <Col md={4}>
              <article className={`p-2 ${isChart5Shown ? 'card shadow cardCss' : ''}`}>
                {isChart5Shown && <h4 className="card-title pt-2 mb-0 text-center chart-title">Month On Month Comparison (Completed)</h4>}
                <div data-testid="patientsByPaymentStatus5" className={`location-patients-container ${isChart5Shown ? 'card-body' : ''}`}>
                  <canvas id="patientsByPaymentStatus5"></canvas>
                </div>
              </article>
            </Col>
            <Col md={4}>
              <article className={`p-2 ${isChart6Shown ? 'card shadow cardCss' : ''}`}>
                {isChart6Shown && <h4 className="card-title pt-2 mb-0 text-center chart-title">Month On Month Comparison (Abandoned)</h4>}
                <div data-testid="patientsByPaymentStatus6" className={`payment-patients-container ${isChart6Shown ? 'card-body' : ''}`}>
                  <canvas id="patientsByPaymentStatus6"></canvas>
                </div>
              </article>
            </Col>

          </Row>
        </Container>

        <Container fluid>
          <Row md={4} m={12} className="d-flex">
            <Col md={4}>
              <article className={`p-2 ${isChart7Shown ? 'card shadow cardCss' : ''}`} >
                {isChart7Shown && <h4 className="card-title pt-2 mb-0 text-center chart-title">Daily Enrollment Count</h4>}
                <div data-testid="patientsByPaymentStatus7" className={`status-patients-container ${isChart7Shown ? 'card-body' : ''}`}>
                  <canvas id="patientsByPaymentStatus7"></canvas>
                </div>
              </article>
            </Col>
          </Row>
        </Container>


        <Container fluid>
          <Row md={6} m={12} className="d-flex">
            <Col md={6}>
              <article className={`p-2 ${isChart8Shown ? 'card shadow cardCssweekly' : ''}`}>
                {isChart8Shown && (
                  <h4 className="card-title pt-2 mb-0 text-center chart-title">UrgentCare Weekly Counts</h4>
                )}
                <div data-testid="patientsByPaymentStatus8" className={`status-patients-containerweekly ${isChart8Shown ? 'card-body' : ''}`} style={{ height: '500px' }}>
                  <canvas id="patientsByPaymentStatus8"></canvas>
                </div>
              </article>
            </Col>
            <Col md={6}>
              <article className={`p-2 ${isChart9Shown ? 'card shadow cardCssMonthly' : ''}`}>
                {isChart9Shown && (
                  <h4 className="card-title pt-2 mb-0 text-center chart-title">UrgentCare Monthly Counts</h4>
                )}
                <div data-testid="patientsByPaymentStatus9" className={`status-patients-containermonthly ${isChart9Shown ? 'card-body' : ''}`} style={{ width: '900px', height: '500px' }}>
                  <canvas id="patientsByPaymentStatus9"></canvas>
                </div>
              </article>
            </Col>
          </Row>
        </Container>
        </div>
     

    </main>

  );
}

export default Dashboard;

