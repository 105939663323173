// export const BASE_URL = process.env.REACT_APP_BASE_URL;
// export const BASE_URL_PAYMENT = process.env.REACT_APP_BASE_URL_PAYMENT;
// export const S3BUCKET_IMAGE_URL = process.env.REACT_APP_S3BUCKET_IMAGE_URL;
// export const S3BUCKET_NOIMAGE_URL = process.env.REACT_APP_S3BUCKET_NOIMAGE_URL;
// export const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
// export const GET_GENDER_RACE_ETHNICITY_LANGUAGE_URL = "resource?resourceType=Race,Gender,Ethnicity,Language";
// export const BASE_URL_PATIENT = "patient/";
// export const GET_LOCATION_API="location?locationType=CMDOffice";
// export const GET_PRACTITIONER_URL="provider?locationId=";
// export const GET_PRICES_ANTHEM = "config?configType=Anthem";
// export const GET_PRICES_MEDICARE = "config?configType=Medicare";
// export const GET_PRICES_SELFPAY = "config?configType=SelfPay"
// export const CAPTCHA_API =·"https://www.google.com/recaptcha/api.js?render=";
// export const GET_DASHBOARD_CONFIG_URL = "dashboardconfig";
// export const GET_DASHBOARD_URL = "dashboard";
// export const GET_DASHBOARDSTATS_URL = "dashboardstats";
// export const POST_PROVIDERLOCATION_URL = "providerlocation";
// export const GET_PROVIDERLOCATION_URL = "providerlocation";
// export const GET_OTHERPATIENT_URL = "otherpatients";
// export const POST_OTHERPATIENT_URL = "otherpatients";

export const BASE_URL_PAYMENT = "https://ux5qjhgp14.execute-api.us-east-1.amazonaws.com/PaymentManagement_v1/"
export const S3BUCKET_IMAGE_URL="https://dagm2ph4l5io7.cloudfront.net/";
export const S3BUCKET_NOIMAGE_URL="https://dagm2ph4l5io7.cloudfront.net/No_ProfileImage.png";
export const STRIPE_PUBLISHABLE_KEY = "pk_test_51MA523ADQwJN00E0agkM193pZ9PamAxQpI4I4sWN0EFRtLcizkEzStyVkLLNVUA3OuOyzyZDOCqLW1zGkSLzWDuG00unaxjiYg";
export const BASE_URL=process.env.REACT_APP_BASE_URL;
export const GET_GENDER_RACE_ETHNICITY_LANGUAGE_URL = "resource?resourceType=Race,Gender,Ethnicity,Language";
export const BASE_URL_PATIENT = "patient/";
export const GET_LOCATION_API="location?locationType=CMDOffice";
export const GET_PRACTITIONER_URL="provider?locationId=";
export const GET_PRICES_ANTHEM = "config?configType=Anthem";
export const GET_PRICES_MEDICARE = "config?configType=Medicare";
export const GET_PRICES_SELFPAY = "config?configType=SelfPay";
export const CAPTCHA_API ="https://www.google.com/recaptcha/api.js?render=";
export const GET_DASHBOARD_CONFIG_URL = "dashboardconfig";
export const GET_DASHBOARD_URL = "dashboard";
export const GET_DASHBOARDSTATS_URL = "dashboardstats";
export const POST_PROVIDERLOCATION_URL = "providerlocation";
export const GET_PROVIDERLOCATION_URL = "providerlocation";
export const GET_OTHERPATIENT_URL = "otherpatients";
export const POST_OTHERPATIENT_URL = "otherpatients";
export const GET_DOWNLOAD_URL = "dashboarddatadownload";
export const GET_LOGIN_URL = "login";
export const GET_DROPDOWNPROVIDERS_URL ="providers-locations";
export const GET_ALLUSER_URL="allusers";
export const GET_APPUSER_URL="appuser";
export const  PUT_APPUSER_URL='appuser';
export const POST_APPUSER_URL='appuser';
export const POST_IMAGE_URL='providers/image';
export const POST_BIO_URL='providers/biography';
export const GET_PROVIDER_FILES_URL='provider/files';