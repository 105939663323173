import React, { useState, useEffect,useRef } from "react";
import Select from "react-select";
import { styled } from "@mui/joy";
import { ApiCall, GET, POST } from "../utils";
import {POST_IMAGE_URL,POST_BIO_URL,GET_PROVIDER_FILES_URL} from "../constants";
import './addProviderDetails.css';
import Modal from "react-modal";


const VisuallyHiddenInput = styled("input")`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const nonEditableKeys = ["name","degree","education", "certification"];
export default function AddMappingPage() {
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedDescription, setSelectedDescription] = useState(null);
  const [imageFileUploaded, setImageFileUploaded] = useState(false);
  const [descriptionFileUploaded, setDescriptionFileUploaded] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [fullNamesDropdown, setFullNamesDropdown] = useState([]);
  const [apiResponse, setApiResponse] = useState([]);
  const [providerData, setProviderData] = useState([]); 
  const [selectedUserDetails, setSelectedUserDetails] = useState(null);
  const [selectedProviderId, setSelectedProviderId] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [descriptionUrl, setDescriptionUrl] = useState(null);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [modalImageUrl, setModalImageUrl] = useState(null);
  const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false);
  const [modalDescriptionUrl, setModalDescriptionUrl] = useState(null);
  const [editableValues, setEditableValues] = useState({});
  const [hoveredKey, setHoveredKey] = useState(null);
  const [editingKey, setEditingKey] = useState(null);
  const [savedDescription, setSavedDescription] = useState(null);  
  const [savedImage, setSavedImage] = useState(null);
  const [isViewDescriptionModalOpen, setIsViewDescriptionModalOpen] = useState(false);
  const [modalViewDescriptionUrl, setModalViewDescriptionUrl] = useState(null);
  const [editingState, setEditingState] = useState({});
  


  const inputRef = useRef(null);
  const handleViewDescriptionClick = (event) => {
    event.preventDefault(); 
    setIsViewDescriptionModalOpen(true);
    setModalViewDescriptionUrl(descriptionUrl);
  };

  const handleEditClick = (key, value) => {
    const nonEditableKeys = ["education", "certification"];
    if (!nonEditableKeys.includes(key)) {
      setEditingState({ ...editingState, [key]: true });
      setEditableValues({ ...editableValues, [key]: value });
      inputRef.current.focus();
    }
  };
  
  
  

  const handleValueChange = (key, event) => {
    setEditableValues({ ...editableValues, [key]: event.target.value });
  };

  const handleSaveClick = () => {
    if (Object.keys(editableValues).length > 0) {
      const updatedDescription = { ...JSON.parse(modalDescriptionUrl), ...editableValues };
      const content = JSON.stringify(updatedDescription, null, 2); 
      const txtBlob = new Blob([content], { type: 'text/plain' });
      setSavedDescription(txtBlob);
      setEditableValues({});
      setIsDescriptionModalOpen(false);
      setEditingState({});
    }
  };
 
  const handleImageClick = (imageUrl, event) => {
    event.preventDefault(); 
    setIsImageModalOpen(true);
    setModalImageUrl(imageUrl);
  };

  const handleDescriptionClick = (descriptionUrl, event) => {
    event.preventDefault();
    setIsDescriptionModalOpen(true);
    setModalDescriptionUrl(descriptionUrl);
};

  
const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "image/png") {
      setSelectedImage(file);
      setImageFileUploaded(true);
    } else {
      setSelectedImage(null);
    }
  };

  const handleDescriptionChange = (event) => {
    const file = event.target.files[0];
    setDescriptionFileUploaded(false);
    setSelectedDescription(file);
    setDescriptionFileUploaded(true);
  };
  
 

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      try {
         await ApiCall(GET_PROVIDER_FILES_URL, null, GET, null, function (response) {
          if (response?.status === 200) {
          const sortedData = response.data.sort((a, b) => a.providerName.localeCompare(b.providerName));
            setApiResponse(response.data);
            const data = response.data.map(provider => ({
              id: provider.providerId,
              fullName: provider.providerName,
              providerInfo: {
                providerId: provider.providerId,
                providerName: provider.providerName,
                imageDataBase64: provider.imageDataBase64,
                bioDataBase64: provider.bioDataBase64
              }
            }));
            setProviderData(data);
            setFullNamesDropdown(sortedData.map(provider => provider.providerName));
          }
          else{
            setErrorMessage(response.data.message);
          }
        });
      } catch (error) {
        console.error("Error fetching data from API:", error);
      }
    };

    fetchDropdownOptions();
    if (selectedUserDetails) {
        setImageUrl((selectedUserDetails.imageDataBase64));
        setDescriptionUrl(decodeBase64(selectedUserDetails.bioDataBase64));
      }
    }, [selectedUserDetails]);
 
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setImageFileUploaded(false);
    if (file && file.type === "image/png") {
      setSelectedImage(file);
      setImageFileUploaded(true); 
    } else {
        setSelectedImage(null);
      }
  };
  
  
  const handleDescriptionUpload = (event) => {
    const file = event.target.files[0];
    setDescriptionFileUploaded(false);
    setSelectedDescription(file);
    setDescriptionFileUploaded(true); 
  };

    const convertBase64ToImage = (savedImage) => {
      const byteCharacters = atob(savedImage);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'image/png' });
    return blob;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
   const imageFormData = new FormData();
   if (!selectedImage) {
    const pngBinaryData = convertBase64ToImage(savedImage);
    imageFormData.append('file', pngBinaryData);
   }else {
    imageFormData.append('file', selectedImage);
  }

  const descriptionFormData = new FormData();
  if (selectedDescription) {
    descriptionFormData.append('file', selectedDescription);
  } else {
    descriptionFormData.append('file', savedDescription);
  }
    
  
    try {
       const newProviderId =selectedProviderId ;
       imageFormData.append('providerId',newProviderId);
       descriptionFormData.append('providerId',newProviderId);

       const imageUploadUrl = POST_IMAGE_URL;

      await ApiCall(
         imageUploadUrl,
         imageFormData,
         POST,
         {
           'Content-Type': 'multipart/form-data',
         },
         handleApiResponse
       ); 
 
       const descriptionUploadUrl = POST_BIO_URL;
       await ApiCall(
         descriptionUploadUrl,
         descriptionFormData,
         POST,
         {
           'Content-Type': 'multipart/form-data', 
         },
         handleApiResponse
       );
       setShowSuccessPopup(true);
      setTimeout(() => {
        window.location.reload();
      }, 2000);

    } catch (error) {
      console.log("An error occurred while submitting the form:", error);
    }
  };
  
    const handleApiResponse = (response) => {
      return response;
    };
    const SuccessPopup = () => (
        <div className="success-popup">
          <p>Submitted successfully!</p>
        </div>
      );
      const decodeBase64 = (base64String) => {
        const paddedString = base64String + '='.repeat((4 - base64String.length % 4) % 4);
        try {
          return atob(paddedString);
        } catch (error) {
          return null;
        }
      };
      const textBoxRef = useRef(null);

      useEffect(() => {
        const handleClickOutside = (event) => {
          if (editingKey && textBoxRef.current && !textBoxRef.current.contains(event.target)) {
            setEditingKey(null);
          }
        };
      
        document.addEventListener("mousedown", handleClickOutside);
      
        // Clear editing state when the modal is opened
        if (isDescriptionModalOpen) {
          setEditingState({});
        }
      
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [editingKey, isDescriptionModalOpen]);


      const formatKey = (key) => {
        if (key === "what_keeps_healthy") {
          return "What keeps me healthy";
        } else if (key === "certification") {
          return "Board Certification";
        } else {
          return key
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
        }
      };
      
  return (
    <div data-testid="add-mapping">
        <h2
      htmlFor="practitionerName"
      style={{ gap: "30px",marginTop:"100px",display:'flex',justifyContent:"center",alignContent:"center" }}
    >
      Add Provider Details
    </h2>
    <div className="container" >
          
      <div
        className="TopHeaderWrapper"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
      
      </div>
      <div style={{marginTop:'20px'}}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      > 
    <br/>  <br/>
        {" "}
        <label
          htmlFor="practitionerName"
         className="Practitioner-label"
        >
          Provider Name
        </label>
      </div>
      <div
        className="form-group"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div></div>
        <Select
                  options={fullNamesDropdown.map((fullName, index) => ({
                    value: index,
                    label: fullName,
                  }))}
                  onChange={async (selectedOption) => {
                    const selectedIndex = selectedOption.value;
                    const selectedProvider = providerData[selectedIndex]?.providerInfo;
                    
                    if (selectedProvider) {
                      setSavedImage(selectedProvider.imageDataBase64);
                  
                    setImageFileUploaded(false);
                    setDescriptionFileUploaded(false);
                    setSelectedProviderId(selectedProvider.providerId);

                    const userDetails = apiResponse.find(provider => provider.providerId === selectedProvider.providerId);

                    if (userDetails) {
                      setSelectedUserDetails(userDetails);
                    }
                  }}}
                  className="Practitioner-dropdown-option"
                  classNamePrefix="react-select"
                  placeholder="Select Name"
                  aria-label="Select practitioner name"
                  
                />
        <div></div>
      </div>
    
                  {showSuccessPopup && <SuccessPopup />}
              
                {selectedUserDetails && (
            <div className="ThirdWrapper" style={{  display: "flex",justifyContent: "center", flexDirection: "column" }}>
              {selectedUserDetails.imageDataBase64 === "Image is not present." ? (
            <div>
              <label htmlFor="imageInput">
            <input
              type="file"
              id="imageInput"
              onChange={handleImageUpload}
              style={{ display: "none" }}
            />
            <div className="upload-btn-content">
            <i className="fas fa-image" style={{alignItems:'center',marginLeft:'30px'}}></i>
              <span>Upload Image</span>
            </div>
            <VisuallyHiddenInput type="file" onChange={handleImageChange} />
          </label>
             
            { imageFileUploaded && selectedImage && (
              <div style={{ marginTop: 10, fontSize: "15px", color: '#264e5a' }}>
                  Image uploaded successfully!
              </div>
          )}
  </div>
  
        ) : (
          <div>
          <label htmlFor="imageInput">
          <input
            type="file"
            id="imageInput"
            onChange={handleImageUpload}
            style={{ display: "none" }}
          />
          <div className="upload-btn-content">
          <i className="fas fa-image" style={{alignItems:'center',marginLeft:'30px'}}></i>
            <span>Update Image</span>
          </div>
          <VisuallyHiddenInput type="file" onChange={handleImageChange} />
        </label>

      <div> 
      {imageUrl && (
                    <div style={{ marginTop: 10, fontSize: "15px" }}>
                      <a href="#" onClick={(event) => handleImageClick(imageUrl, event)}>
                        View Image
                      </a>
                    </div>
                  )}
        </div>
        <div style={{ gap: "30px",display:'flex',flexDirection:'row' }}>
      
       {imageFileUploaded && selectedImage && (
    <div style={{ marginTop: 10, fontSize: "15px", color: '#264e5a' }}>
        Image uploaded successfully!
    </div>
)}
         <Modal 
          isOpen={isImageModalOpen}
          onRequestClose={() => setIsImageModalOpen(false)}
          contentLabel="View Image Modal"
          style={{
            overlay: {
              overflow:'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
            content: {
              overflow:'auto',
              width:'auto',
              maxWidth: '500px',
              height: 'auto',
              position: 'static',
              margin: 'auto',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '20px',
              background: 'white',
              borderRadius: '32px',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
            },
          }}
        >
          <img src={`data:image/jpeg;base64,${modalImageUrl}`}
          alt="User Image" style={{ width: "auto", height: "auto" }} />
           <br />
            <button className="landing-close-btn" onClick={() => setIsImageModalOpen(false)}>
              Close
            </button>
        </Modal>
   <Modal
  isOpen={isDescriptionModalOpen}
  onRequestClose={() => setIsDescriptionModalOpen(false)}
  contentLabel="View Description Modal"
  style={{
    overlay: {
      overflow: 'auto',
      paddingTop: '100px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
      overflow: 'auto',
      maxWidth: '500px',
      height: 'auto',
      position: 'static',
      margin: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px',
      background: 'white',
      borderRadius: '32px',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    },
  }}
>
  {modalDescriptionUrl && (
    <div>
      <h3 style={{ textAlign: 'center' }}> Provider Details</h3>
      {Object.entries(JSON.parse(modalDescriptionUrl)).map(([key, value]) => (
  <div
    key={key}
    style={{
      margin: '15px 0',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'justify',
      paddingLeft: '10px',
      paddingRight: '10px',
    }}
    onMouseEnter={() => setHoveredKey(key)}
    onMouseLeave={() => setHoveredKey(null)}
  >
    <h5><strong>{formatKey(key)}</strong></h5>
    {editingState[key] ? (
      (key === 'name' || key === 'degree' || key === 'patient_age' || key === 'certification' || key === 'pronouns') ? (
        <input
        autoFocus
          type="text"
          value={editableValues[key] || value}
          onChange={(event) => handleValueChange(key, event)}
          onBlur={() => setEditingState({ ...editingState, [key]: false })}
          style={{ fontSize: '16px' }}
        />
      ) : (
        <textarea
        autoFocus
          value={editableValues[key] || value}
          onChange={(event) => handleValueChange(key, event)}
          onBlur={() => setEditingState({ ...editingState, [key]: false })}
          style={{ width: '100%', minHeight: '150px', fontSize: '16px' }}
        />
      )
    ) : (
      <span>
        {editableValues[key] || value}
        {/* Disable edit icon for certain keys */}
        {hoveredKey === key && !nonEditableKeys.includes(key) && (
          <span
            className="edit-icon"
            onClick={() => handleEditClick(key, value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleEditClick(key, value);
              }
            }}
            tabIndex={0}
            style={{ marginLeft: '10px', cursor: 'pointer', color: nonEditableKeys.includes(key) ? '#999999' : 'black' }}
          >
            {!nonEditableKeys.includes(key) && '✎'}
          </span>
        )}
      </span>
    )}
  </div>
))}


      {Object.keys(editableValues).length > 0 && (
        <button className="landing-submit-btn" onClick={handleSaveClick} style={{ marginTop: '10px' }}>
          Save
        </button>
      )}
    </div>
  )}
  <button className="landing-close-btn" onClick={() => setIsDescriptionModalOpen(false)}>
    Close
  </button>
</Modal>

<Modal
    isOpen={isViewDescriptionModalOpen}
    onRequestClose={() => setIsViewDescriptionModalOpen(false)}
    contentLabel="View Description Modal"
    style={{
      overlay: {
        overflow:'auto',
        paddingTop:'100px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      },
      content: {
        overflow:'auto',
        marginTop:'500px',
        maxWidth: '500px',
        height: 'auto',
        position: 'static',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        background: 'white',
        borderRadius: '32px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
      },
    }}
>
    {modalViewDescriptionUrl ? (
        <div >
            <h3 className="single-practitioner-title" style={{ textAlign: 'center' }}> Provider Details</h3>
            {Object.entries(JSON.parse(modalViewDescriptionUrl)).map(([key, value]) => (
                <div key={key} style={{ margin: '15px 0',display:'flex',flexDirection:'column',textAlign:'justify',paddingLeft:'10px',paddingRight:'10px' }}>
                    <h5><strong>{formatKey(key)}</strong></h5>
                    <span style={{ fontSize: '16px' }}>{value}</span>
                </div>
            ))}
            <button className="landing-close-btn" onClick={() => setIsViewDescriptionModalOpen(false)}>
                Close
            </button>
        </div>
    ) : (
        <p>
            {modalViewDescriptionUrl === null ? "modalViewDescriptionUrl is null" : "Loading description..."}
        </p>
    )}
</Modal>
        </div>
         </div>
    )}
    {selectedUserDetails.bioDataBase64 === "Bio is not present." ? (
  <div>
   <label htmlFor="descriptionInput">
  <input
    type="file"
    id="descriptionInput"
    onChange={handleDescriptionUpload}
    style={{ display: "none" }}
  />
  <div className="upload-btn-content">
    <i className="fas fa-file-alt"style={{alignItems:'center',marginLeft:'30px'}}></i>
    <span>Upload Description</span>
  </div>
  <VisuallyHiddenInput
              type="file"
              onChange={handleDescriptionChange}
            />
</label>

    {descriptionFileUploaded && selectedDescription && (
      <div style={{ marginTop: 10, color:'#264e5a', fontSize: "14px" }}>
        Description uploaded successfully!
      </div>
    )}
  </div>
) : (
  <div>
 <label htmlFor="descriptionInput">
    <input
      type="file"
      id="descriptionInput"
      onClick={(event) => handleDescriptionClick(descriptionUrl, event)}
      style={{ display: "none" }}
    />
    <div className="upload-btn-content">
      <i className="fas fa-file-alt" style={{ alignItems: 'center', marginLeft: '30px' }}></i>
      <span>Update Description</span>
    </div>
    <VisuallyHiddenInput
      type="file"
      onChange={handleDescriptionChange}
    />
  </label>
<div>
{descriptionUrl && (
      <div style={{ marginTop: 10, fontSize: "15px" }}>
        <a href="#" onClick={handleViewDescriptionClick}>
          View Description
        </a>
      </div>
    )}
        </div>
        {descriptionFileUploaded && selectedDescription && (
          <div style={{ marginTop: 10, color:'#264e5a',fontSize:'14px' }}>
            Description uploaded successfully! 
          </div>
        )}
      </div>
    )}
     
      <button className="landing-submit-btn" type="submit" onClick={handleSubmit}>
                  Submit
                </button>
      </div>
    )}

        </div>
        </div>
        {errorMessage && (
          <div className="error-msg" >
            <span>{errorMessage}</span>
          </div>
        )}
        </div>
  );
}
