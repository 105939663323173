import React, { useEffect, useState } from 'react';
import './Sidebar.css';


const Sidebar = () => {
  const [userInfo, setUserInfo] = useState({});
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    const storedData = localStorage.getItem('userDetails');
    console.log('storedData',storedData);
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setUserInfo(parsedData);
    }
  }, [localStorage.getItem('userDetails')]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const getURLFromPermission = (permission) => {
    switch (permission) {
      case 'Location Provider Mapping':
        return 'providerlocationmapping';
      case 'Reports':
        return 'dashboardreport';
        case 'Add User':
        return 'adduser'; 
        case 'Add Provider Details':
        return 'addproviderdetails';
      default:
        return '';
    }
  };

  return (
    <main data-testid="SidebarElement">
    <div className="sidebar-container">

      <button
        className={`openbtn ${sidebarOpen ? 'open' : ''}`}
        onClick={toggleSidebar}
      >
        ☰
      </button>
      <div className={`sidebar ${sidebarOpen ? 'open' : ''}`}data-testid="sidebar">
        <button className="close-sidebar-btn" onClick={toggleSidebar}>
          ✕
        </button>
        <div id="main">
          <ul data-testid="sidebar-list">
            {userInfo?.role === 'Administrator' &&
              userInfo?.permissions.map((permission) => (
                <li key={permission}>
                  <a href={`/${getURLFromPermission(permission)}`}>{permission}</a>
                </li>
              ))}
            {userInfo?.role === 'Viewer' && (
              <li>
                <a href="/">Dashboard</a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
    
  </main>
  );
};

export default Sidebar;