import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Header } from './components';
import { Dashboard } from './containers';
import MyForm from './poc_aggrid/addForm';
import DashComponent from './containers/dashboard/DashboardReport';
import UserList from './containers/dashboard/Userlist';
import ErrorPage from './containers/dashboard/ErrorPage';
import DataTable from './poc_aggrid/providerLocationmapping';
import AddMappingPage from './poc_aggrid/addProviderDetails';


function App() {
  return (
    <div data-testid="app-content" className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="/addForm" element={<MyForm />} />
          <Route path="/dashboardreport" element={<DashComponent />} />
          <Route path="/" element={<Dashboard />} />
          <Route path="/adduser" element={<UserList />} />
          <Route path="/errorpage" element={<ErrorPage />} />
          <Route path="/providerlocationmapping" element={<DataTable />} />
          <Route path="/addproviderdetails" element={<AddMappingPage />} /> 
        </Routes>

      </Router>
    </div>
  );
}
export default App;