import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DashboardReport.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowDown, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { GET_DOWNLOAD_URL } from '../../constants';
import { ApiCall, GET } from '../../utils';
import Papa from 'papaparse';
import Select from 'react-select';

const DashComponent = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedType, setSelectedType] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const formatDate = (date) => {
    if (date !== null) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    } else {
      return null;
    }
  };

  const handleApiResponse = (response) => {
    return response;
  };
 /* istanbul ignore next */
  function downloadCSV(downloadapiresponse) {
    const byteArray = Uint8Array.from(window.atob(downloadapiresponse.data), (c) => c.charCodeAt(0));
    const jsonString = new TextDecoder().decode(byteArray);
    const parsedObject = JSON.parse(jsonString);
    const csvString = Papa.unparse(parsedObject);
    console.log(csvString);

    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'Patient_Record.csv';
    document.body.appendChild(link);
    link.click();

    (async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      URL.revokeObjectURL(url);
      document.body.removeChild(link);
    })();
  }

  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);
  console.log('start date', formattedStartDate);
  console.log('End date', formattedEndDate);
 /* istanbul ignore next */
  const handleIconClick = async (e) => {
    if (formattedStartDate !== null && formattedEndDate !== null) {
      if (new Date(formattedEndDate) < new Date(formattedStartDate)) {
        setErrorMessage('End date must be greater than or equal to start date');
        setTimeout(() => {
          setErrorMessage('');
        }, 5000);
        return;
      }
      try {
        setLoading(true);
        let from = formattedStartDate ? new Date(formattedStartDate).toISOString().replace('00.000Z', '00Z') : '';
        let to = formattedEndDate ? new Date(formattedEndDate).toISOString().replace('00:00:00.000Z', '23:59:59Z') : '';
        let type = selectedType;

        let downloadUrl = `dashboarddatadownload?from=${from}&to=${to}&type=${type}`;

        const downloadApiResponseWithDateRange = await ApiCall(downloadUrl, null, GET, null, handleApiResponse);

        if (downloadApiResponseWithDateRange.status === 200) {
          console.log(downloadApiResponseWithDateRange);
          downloadCSV(downloadApiResponseWithDateRange);
        } else if (downloadApiResponseWithDateRange.status === 400) {
          console.log('Failed to submit form.');
          setErrorMessage('No patient records found');
          setTimeout(() => {
            setErrorMessage('');
          }, 5000);
          return;
        }
        setLoading(false);
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);

        const downloadapiresponse = await ApiCall(GET_DOWNLOAD_URL, null, GET, null, handleApiResponse);

        if (downloadapiresponse.status === 200) {
          downloadCSV(downloadapiresponse);
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const typeOptions = [
    { value: 'All', label: 'All' },
    { value: 'anthem', label: 'Anthem' },
    { value: 'medicare', label: 'Medicare' },
    { value: 'selfPay', label: 'Self-pay' },
    { value: 'urgent_care', label: 'Urgent Care' },
    { value: 'employer_group', label: 'Employer Groups' },
    { value: 'count_urgent_care', label: 'Urgent Care Counts' },
  ];

  const typeSelectStyles = {
    container: (provided) => ({
      ...provided,
      width: '150px', 
      margin: '-10px', 
      marginLeft:'50px',
      marginTop:'-45px',
      
    }),
    control: (provided) => ({
      ...provided,
      width: '150px', 
    }),
  };

  return (
    <div data-testid="date-element" className="container">
      <div className="Reportcard-body">
        <h1 className="text-center py-2 mb-1 ">Reports</h1>
        <div className='download-icon' data-testid="download-icon">
          <FontAwesomeIcon
            icon={faFileArrowDown}
            onClick={handleIconClick}
          />
        </div>
        {loading && <FontAwesomeIcon icon={faSpinner} spin className="loading-icon" />}
        <div className="form-row">
          <div className="form-group">
            <div className="Reportcard">
              <div className='Reportcard-body'>
                <label className="label">Start date</label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className=" date-picker"
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Enter Start Date"
                  minDate={new Date("2022-01-01")}
                  maxDate={new Date()}
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="Reportcard">
              <div className='Reportcard-body'>
                <label className="label">End Date</label>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  className="  date-picker"
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Enter End Date"
                  minDate={startDate || new Date("2022-01-01")}
                  maxDate={new Date()}
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="Reportcard">
              <div className="Reportcard-body">
                <label className="label">Type</label>
                <div data-testid="report-type-dropdown">
                <Select
                  value={typeOptions.find(option => option.value === selectedType)}
                  onChange={(selectedOption) => setSelectedType(selectedOption.value)}
                  options={typeOptions}
                  className="dropdown" 
                  placeholder="Select Type"
                  isSearchable={false}
                  aria-label="Select report type"
                  classNamePrefix="react-select"
                  styles={typeSelectStyles}
                /></div>
              </div>
            </div>
          </div>
        </div>
        {errorMessage && (
          <div data-testid="error-message" className="error-message">
            <span>{errorMessage}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashComponent;
